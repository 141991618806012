import React, { useEffect, useState } from "react";
import VendorHeader from "components/Headers/VendorHeader.js";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Input,
  Button,
  ButtonGroup
} from "reactstrap";

import { Doughnut, Pie, Bar } from 'react-chartjs-2'
import { getVendorDashboardData,getReportByRestaurantId } from "api";
import strings from "lang/strings";
const Dashboard = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != null) {
      // getVendorDashboardData(token).then((res) => {
      //   
      // });
      getReportByRestaurantId().then((res)=>{
        if ('data' in res) {
              if (res.data.length != 0) {
                let chart = {
                  labels: res.data.months,
                  datasets: [
                    {
                      label: 'Orders',
                      backgroundColor: 'rgba(255,99,132,0.2)',
                      borderColor: 'rgba(255,99,132,1)',
                      borderWidth: 1,
                      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                      hoverBorderColor: 'rgba(255,99,132,1)',
                      data: res.data.amount
                    }
                  ]
                }
                setData(chart);
              }
              else {
              }
            } else {
    
            }
      });
      

    } else {
      props.history.push('/');
    }
  }, []);


  return (
    <>
      <VendorHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                <h3 className=" mb-0">{strings.Dashboard}</h3>
              </CardHeader>
              <CardBody>
                <div className="col-md-6">
                  {
                    data.length == 0 ? "No Data Available" : <Bar data={data} />
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Dashboard;