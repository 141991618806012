import React, { useState, useEffect } from "react";
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Form,
  Input, 
  FormGroup, 
  Label,
  Spinner
} from 'reactstrap';
import { Formik } from 'formik';
import swal from 'sweetalert';
import moment from 'moment';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { useDispatch } from 'react-redux';
import { updateRestaurant,getVendors } from '../../api';

const Edit = (props) => {

  const dispatch = useDispatch();
  const [btnDisabled, setDisabled] = useState(false);
  const [vendors, setVendors] = useState([])
  useEffect((props) => {
    getVendors()
      .then((result) => {
        setVendors(result.data)
      })
  }, [])
  let initialValues = {
    id: props.selected.id,
    image: '',
    name: props.selected.name,
    vendor: props.selected.vendor,
    location: '',
    latitude: props.selected.latitude,
    longitude: props.selected.longitude,
    discount: props.selected.discount
  }

  return (
    <Formik
      initialValues={initialValues}

      onSubmit={(values, {resetForm}) => {
        setDisabled(true);
        updateRestaurant(values).then(res => {
          console.log("REsult",res)
          if(res.status == "failure_constraint" || res.status == "failure"){
            swal("Orders Exists",{
              icon:"error"
            })
          }
          props.toggle();
          setDisabled(false);
          resetForm(initialValues);
        });
      }}
      >
      {(form) => (
        
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle}>Edit Restaurant</ModalHeader>
          <ModalBody>
            <Form>
{/* 
              <FormGroup>
                <Label>Image</Label>
                <Input type="file" name="image" onChange={form.handleChange}/>
              </FormGroup> */}

              <FormGroup>
                <Label>Restaurant Name</Label>
                <Input type="text" name="name" value={form.values.name} onChange={form.handleChange}/>
              </FormGroup>

              <FormGroup>
                <Label>Vendor</Label>
                <Input type="select" name="vendor_id" onChange={form.handleChange}>
                  {vendors.map((item, k) => {
                    return (
                      <option 
                        key={k}>
                        {item.name}
                      </option>
                    )
                  })}
                </Input>
              </FormGroup>

              {/*}
              <FormGroup>
                <Label>Location</Label>
                <PlacesAutocomplete
                  value={form.values.location}
                  onChange={(address) => _handleChangeLocation(address, form)}
                  onSelect={(address) => _handleSelectLocation(address, form)}>
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <Input
                        {...getInputProps({
                          placeholder: 'Search Location...',
                          className: 'location-search-input',
                        })} />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={suggestion.description}
                              >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </FormGroup>
              */}

              <FormGroup>
                <Label>Discount</Label>
                <Input type="number" name="discount" value={form.values.discount} onChange={form.handleChange}/>
              </FormGroup>

            </Form>
            
          </ModalBody>
          <ModalFooter>
            {(btnDisabled === true) ? <Spinner /> : 
              <>
                <Button color="primary" onClick={form.handleSubmit}>Submit</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                {/*<Button color="danger" onClick={() => _delete()}>Remove</Button>*/}
              </>
            }
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );

  function _handleChangeLocation(address, form) {
    form.setFieldValue('location', address);
  }

  function _handleSelectLocation(address, form) {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        form.setFieldValue('location', address);
        form.setFieldValue('latitude', latLng.lat.toString());
        form.setFieldValue('longitude', latLng.lng.toString());
      })
      .catch(error => console.error('Error', error));
  }

  function _delete() {
    /*
    swal({
      title: "Remove",
      text: "Are you sure you want to remove this service?",
      icon: "warning",
      buttons: true,
      buttons: {
        cancel: 'Cancel',
        confirm: "Yes",
      },
    })
    .then((isConfirm) => {
      if(isConfirm) {
        var params = {
          key: props.selected._key,
          id: props.selected.data.service_id
        }
        dispatch(deleteService(params));
        props.toggle();
      }
    });
    */
  }
}

export default Edit;