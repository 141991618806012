export const en = {
    title_merchant:"Vendor Panel",
    description_merchant:"Login to vendor panel",
    title_admin:"Admin Panel",
    email:"Email Address",
    email_validation:"Please enter your email",
    password:"Password",
    password_validation:"Please enter your password",
    failure:"Failure",
    invalid_credentials:"Invalid username and password...",
    login:"Login",
    english:"English",
    arabic:"Arabic",
    Dashboard:"Dashboard",
    Restaurant:"Restaurant",
    Report:"Report",
    Settings:"Settings",
    account:"Account",
    Vendor:"Vendor",
    Restaurants:"Restaurants",
    Users:"Users",
    logout:"Logout",
    admin_panel:"Admin CPANEL",
    success:"Success",
    Redirecting:"Redirecting...",
    cpanel:"Control Panel",
    Purchase:"Purchase",
    Account:"Account",
    Language:"Language",
    old_password:"Old Password",
    new_password:"New Password",
    submit:"Submit"
};