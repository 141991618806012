import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header.js";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Input,
  Button,
  ButtonGroup
} from "reactstrap";

import moment from 'moment';
import { createFilter } from 'react-search-input';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { getVendors, deleteVendor } from '../../api';
import swal from 'sweetalert';

import Add from './Add';
import Edit from './Edit';

const columns = (_renderBtn) => [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Date Added',
    cell: row => moment(row.createdAt).format('MMM Do YYYY h:mm a'),
    sortable: true,
  },
  {
    name: '',
    cell: row => _renderBtn(row),
    right: true,
  },
];

const Vendor = (props) => {

  const dispatch = useDispatch();

  const [addModalView, setAddModal] = useState(false);
  const [editModalView, setEditModal] = useState(false);

  const [selected, setSelected] = useState({});
  const [search, setSearch] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [added, setAdded] = useState(true);
  const KEYS_TO_FILTERS = ['data.name'];
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    getVendors()
      .then((result) => {
        setData(result.data)
        console.log('Vendor', data.length);
      });
  }, [addModalView, editModalView]);

  const onChangeValue = (e) => {
    if (e.target.value) {
      let filteredData = data.filter((item) => {
        return item.name && item.name.toLowerCase().includes(e.target.value.toLowerCase())
      })
      console.log("filtered", e.target.value)
      setData(filteredData);
    } else {
      getVendors()
        .then((result) => {
          setData(result.data)

        });
    }
    console.log(added)
  };
  const toggleAdd = () => {
    setAdded(true)
    setAddModal(!addModalView)
  };
  const toggleEdit = () => {
    setEditModal(!editModalView);
    console.log(editModalView);
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                <Button color="default"
                  onClick={() => setAddModal(true)}
                >
                  Add New Vendor
                </Button>
              </CardHeader>
              <CardBody>
                <DataTable
                  title="List of Vendors"
                  columns={columns(_renderBtn)}
                  data={data}
                  pagination
                  // progressPending={isLoading}
                  subHeader
                  subHeaderComponent={
                    <Input onChange={onChangeValue} style={{ width: 250 }} placeholder="Search" />
                  }
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Add
        isOpen={addModalView}
        setAddModal={toggleAdd}
        isClose={toggleAdd}
        refresh={(type) => _refresh(type)}
      />

      {(Object.keys(selected).length !== 0) ?
        <Edit
          isOpen={editModalView}
          toggle={toggleEdit}
          selected={selected}
          refresh={(type) => _refresh(type)}
        />
        : null}
    </>
  )

  function _renderBtn(data) {
    return <p>
      <Button color="default" size="sm" onClick={() => _edit(data)}>Edit</Button>
      <Button color="danger" size="sm" onClick={() => _delete(data)}>Delete</Button>
    </p>
  }

  function _edit(data) {
    setEditModal(true);
    setSelected({});
    setTimeout(() => {
      setSelected(data);
    }, 500);
  }

  function _delete(data) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteVendor(data.id)
            .then((result) => {
              if (result.status == "success") {
                swal("Deleted Successfully", {
                  icon: "success",
                });
                getVendors()
                  .then((result) => {
                    setData(result.data)

                  });
              } else if (result.status == "failure_constraint") {
                swal("Cannot delete. Restaurants Exist!", {
                  icon: "error",
                });
              }
            })
        }
      });
  }

  function _refresh(type) {
    if (type === 'add') {
      setAddModal(false);
    } else {
      setEditModal(false);
    }

    dispatch(getVendors());
  }
}

export default Vendor;