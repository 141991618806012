import axios from 'axios';
import { useHistory } from 'react-router-dom';
const BASE_URL = "https://merchant.thmin.com/api/v1";
// const BASE_URL = "http://localhost:5000/api/v1";
const LOCAL_URL = "http://localhost:3000/api/v1";
// const temp_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjozLCJuYW1lIjoiVmVuZG9yIiwiZW1haWwiOiJ2ZW5kb3JAdGhhbWluLmNvbSIsInBhc3N3b3JkIjoiJDJhJDEwJFZCMVplLjB2YXlyMk51Ty9sL2JyaS5MT0g5T21yb2dXaWE3S2d4bmdaYWc2STcudUE0R3BxIiwiY3JlYXRlZEF0IjoiMjAyMS0wMS0xMVQxMzozNjowMi4wMDBaIiwidXBkYXRlZEF0IjoiMjAyMS0wMS0xMVQxMzozNjowMi4wMDBaIiwiZGVsZXRlZEF0IjpudWxsfSwiaWF0IjoxNjEwMzcyMTc5fQ.8AHYN9xlkbE7V_pBCOeHxR6a3vtnzeDevI1atFdUwu4";
const admin_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjoxMSwibmFtZSI6Ik5hdmVlZCBBbmp1bSIsImVtYWlsIjoibmF2ZWVkYW5qdW0xMjEzQGhvdG1haWwuY29tIiwicGFzc3dvcmQiOiIkMmEkMTAkS2VwdjkzQi5RV0xTeUloejB6RzBXdTZlNXEvaHBxSC4yYjI5NEdlYnRHak5iRjhKeWY5aDIiLCJjcmVhdGVkQXQiOiIyMDIwLTExLTA0VDEzOjI2OjUyLjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIwLTExLTA0VDEzOjI2OjUyLjAwMFoifSwiaWF0IjoxNjEyMTgxMzIzfQ.gvfAgbrOcu2LWtzqrD2Wh__KVDeW5aXL7nHgUxRb_Nk";
const google_key = "AIzaSyBabY1X-H8Sx0R3OTNZuqBvRqjMhvq-yFE";
const token = localStorage.getItem("token")
//
// for admin
//
const login = (state) => {
    let data = {};
    data.email = state.email;
    data.password = state.password;

    return axios.post(BASE_URL + '/login', data).then(res => {
        return res.data;
    });
}

const validateToken = () => {
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(BASE_URL + '/validate-token', config).then(res => {
        return (res.data);
    })
    .catch((err) => {
        return err
    });
}

const getAdminDashboardData = () => {
    const token = localStorage.getItem("token")
    console.log("token", token)
    return axios.get(BASE_URL + "/dashboard", {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            console.log(res)
            return res.data;
        });
}

export const getQrCodeData = (id) => {
    const token = localStorage.getItem("token")
    return axios.get(`${BASE_URL}/get-qrcode/${id}`,{
        headers:{ Authorization: `Bearer ${token}` }
    })
    .then((result) => {
        return result.data;
    })
}


const addVendor = (state) => {
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    var formData = new URLSearchParams();
    formData.append('name', state.name);
    formData.append('email', state.email);
    formData.append('password', state.password);

    return axios.post(BASE_URL + '/vendors', formData, config).then(res => {
        return (res);
    });
}

const updateVendor = (state) => {
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    var formData = new URLSearchParams();
    formData.append('email', state.email);

    return axios.patch(BASE_URL + '/vendors/' + state.id, state, config).then(res => {
        return (res);
    });
}

export const resetPassword = (data) => {
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    var formData = new URLSearchParams();
    formData.append('old_password', data.old_password);
    formData.append('new_password', data.new_password);

    return axios.post(BASE_URL + '/vendor/reset-password' , formData, config).then(res => {
        return (res);
    });
}

export const deleteVendor = (id) => {
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.delete(`${BASE_URL}/vendors/${id}`, config).then(res => {
        return (res.data);
    });
}


const getVendorByID = (id) => {
    const token = localStorage.getItem("token")
    axios.get(BASE_URL + '/vendors/' + id).then(res => {
    });
}

const getVendors = () => {
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return axios.get(BASE_URL + '/vendors', config).then(res => {
        if (res.data.status === 'success') {
            return res.data;
        } else {
        }
    });
}

const getRestaurantsForAdmin = () => {
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(BASE_URL + '/restaurants', config).then(res => {
        return res.data;
    });
}

const addRestaurantForAdmin = (state) => {
    const token = localStorage.getItem("token")
    var request = {};
    const configs = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    request.vendor_id = state.vendor_id;
    request.latitude = state.latitude;
    request.longitude = state.longitude;
    request.discount = state.discount;
    request.name = state.name;
    // request.image = res.data.data.name
    return axios.post(`${BASE_URL}/restaurants`, request, configs)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
    // const data = {};
    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };
    // console.log("Request",state);
    // var formData = new FormData();
    // formData.append('image', state.image);
    // return axios.post(BASE_URL + '/upload', formData, config).then(res => {

    // });
}

const updateRestaurant = async (params) => {
    console.log("params",params);
    const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return axios.patch(BASE_URL + '/restaurants/' + params.id, params, config).then(res => {
        return res.data;
    });
}

const getOrdersForAdmin = (params) => {
    const token = localStorage.getItem("token")
    return axios.get(`${BASE_URL}/orders`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then((result) => {
            return result.data;
        })
}

//
// for vendor
//
const validateMerchantToken = (token) => {
    // const token = localStorage.getItem("token")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(BASE_URL + '/validate-merchant-token', config).then(res => {
        return (res.data);
    });
}

const loginVendor = (state) => {
    const token = localStorage.getItem("token")
    var data = {};
    data.email = state.email;
    data.password = state.password;

    return axios.post(BASE_URL + '/vendor/login', data).then(res => {
        return res.data
    }).catch(err => err)
}

const getVendorDashboardData = () => {
    const token = localStorage.getItem("token")
    return axios.get(BASE_URL + "/vendor/reportall", {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            return res.data;
        });
}

const getRestaurantOrders = (token, page) => {
    const vendor_id = JSON.parse(localStorage.getItem("user")).id;
    const data = {};
    data.vendor_id = vendor_id;
    return axios.post(BASE_URL + `/vendor/reportall?page=${page}`, data, {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {

            return res.data.data;
        });
}

const getAllRestaurantsList = (token, page) => {
    return axios.get(BASE_URL + `/vendor/restaurant-list?page=${page}`, {
        headers: { Authorization: `Bearer ${token}` }
    })
        .then(res => {
            return res.data.data;
        });
}

const getAddressFromLatLng = async (lat, lng) => {

    let address = axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_key}`)
        .then(res => {
            if (res.data.results.length !== 0) {
                return res.data.results[0].formatted_address
            } else {
                return "No Address Added";
            }
        })
    return address;
}

const getReportByRestaurantId = () => {
    const token = localStorage.getItem("token")
    var data = {};
    data.restaurant_id = 1;
    return axios.post(`${BASE_URL}/vendor/report`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then((response) => {
            return response.data;
        });
}

const addRestaurant = (data) => {
    const token = localStorage.getItem("token")
    var request = {};
    request.vendor_id = data.vendor_id;
    request.latitude = data.lat;
    request.longitude = data.lng;
    request.discount = 10;
    request.name = data.name;
    // request.image = res.data.data.name
    return axios.post(`${BASE_URL}/vendor/addRestaurant`, request, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response) => {
        return response;
    });
    // var formData = new FormData();
    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };
    // if (data.image) {
    //     formData.append('image', data.file);
    //     return axios.post(BASE_URL + '/upload/', formData, config).then(res => {

    //     });
    // }

    

}



export {
    login,
    validateToken,
    loginVendor,
    validateMerchantToken,
    getRestaurantOrders,
    addRestaurant,
    getReportByRestaurantId,
    getVendorDashboardData,
    getAllRestaurantsList,
    getAddressFromLatLng,
    getAdminDashboardData,
    getVendors,
    getVendorByID,
    addVendor,
    updateVendor,
    getRestaurantsForAdmin,
    addRestaurantForAdmin,
    getOrdersForAdmin,
    updateRestaurant
};


