import React, { useState, useEffect } from "react";
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Form,
  Input, 
  FormGroup, 
  Label,
  Spinner
} from 'reactstrap';
import { Formik } from 'formik';
import swal from 'sweetalert';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateVendor } from '../../api';

const Edit = (props) => {
  console.log("Props",props);
  const dispatch = useDispatch();
  const [btnDisabled, setDisabled] = useState(false);
  
  let initialValues = {
    id: props.selected.id,
    name : props.selected.name,
    email: props.selected.email
  }

  return (
    <Formik
      initialValues={initialValues}

      onSubmit={(values, {resetForm}) => {
        setDisabled(true);
        updateVendor(values).then(res => {
          props.toggle();
          setDisabled(false);
          resetForm(initialValues);
        });
      }}
      >
      {(form) => (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle}>Edit Vendor</ModalHeader>
          <ModalBody>
            
            <Form>
            <FormGroup>
                <Label>Name</Label>
                <Input type="text" name="name" value={form.values.name} onChange={form.handleChange}/>
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input type="email" name="email" value={form.values.email} onChange={form.handleChange}/>
              </FormGroup>
            </Form>
            
          </ModalBody>
          <ModalFooter>
            {(btnDisabled === true) ? <Spinner /> : 
              <>
                <Button color="primary" onClick={form.handleSubmit}>Submit</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                {/*<Button color="danger" onClick={() => _delete()}>Remove</Button>*/}
              </>
            }
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );

  function _delete() {
    /*
    swal({
      title: "Remove",
      text: "Are you sure you want to remove this service?",
      icon: "warning",
      buttons: true,
      buttons: {
        cancel: 'Cancel',
        confirm: "Yes",
      },
    })
    .then((isConfirm) => {
      if(isConfirm) {
        var params = {
          key: props.selected._key,
          id: props.selected.data.service_id
        }
        dispatch(deleteService(params));
        props.toggle();
      }
    });
    */
  }
}

export default Edit;