import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { Formik } from 'formik';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { login, validateToken } from '../../api';
import { LoginAction } from '../../redux/creators/index'
import { useHistory } from "react-router-dom";
import styles from './login.module.css';
import strings,{updateLanguageCallback} from 'lang/strings';
const Login = (props) => {
  const dispatch = useDispatch();
  const [btnDisabled, setDisabled] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const history = useHistory();

  useEffect(() => {
    const loggedIn = localStorage.getItem('token');
    // if (loggedIn !== null) {
    //   validateToken(loggedIn).then(res => {
    //     if (res === 'Authorized') {
    //       props.history.push('/admin/dashboard');
    //     } else {
    //       window.localStorage.removeItem('@thamin_admin');
    //       return false;
    //     }
    //   });
    // }

  }, []);
  const handleLanguageChange = (value) => {
    if (updateLanguageCallback)
      updateLanguageCallback(value)
  }
  return (
    <div className="main-content">
      <div className="header bg-gradient-primary py-7 py-lg-8">
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <img src={require('../../assets/img/logo.png')} width="80" />
                  <h1 className="">{strings.cpanel}</h1>
                  <p className="text-lead">
                    {strings.admin_panel}
                  </p>
                </div>

                <Formik
                  initialValues={{
                    email: '',
                    password: ''
                  }}
                  onSubmit={(values) => {

                    if (values.email === '') {
                      setErrorEmail(strings.email_validation);
                      return false;
                    }

                    if (values.password === '') {
                      setErrorPassword(strings.password_validation);
                      return false;
                    }

                    setDisabled(true);
                    login(values).then(res => {
                      if (res.status === 'success') {
                        swal({
                          title: strings.success,
                          text: `${strings.Redirecting}...`,
                          icon: 'success',
                          timer: 2500,
                          buttons: false,
                        })
                          .then(() => {
                            dispatch(LoginAction(res.data))
                            window.localStorage.setItem('user', res.data.user);
                            window.localStorage.setItem('token', res.data.token);
                            history.push('admin/dashboard')
                          });
                      } else {
                        swal('Required', res.message, 'error');
                        setDisabled(false);
                        return false;
                      }
                    });
                  }}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit}>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={strings.email}
                            type="text"
                            name="email"
                            value={props.values.email}
                            onChange={props.handleChange}
                          />
                        </InputGroup>
                        <span style={{ color: 'red', fontSize: 12 }}>{errorEmail}</span>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={strings.password}
                            type="password"
                            name="password"
                            value={props.values.password}
                            onChange={props.handleChange} />
                        </InputGroup>
                        <span style={{ color: 'red', fontSize: 12 }}>{errorPassword}</span>
                      </FormGroup>
                      <div className="text-center">
                        {(btnDisabled === true) ? <Spinner /> :
                          <Button block type="submit" className="my-4" color="primary">
                            {strings.login}
                          </Button>
                        }
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className={styles.languageSelect}>
                  <label onClick={() => handleLanguageChange("en")}>{strings.english}</label>
                  <label onClick={() => handleLanguageChange("ar")}>{strings.arabic}</label>
                </div>
              </CardBody>
            </Card>
            {/*
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6" />
            </Row>
            */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login;