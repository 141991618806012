const initialState = {
  token: null,
  user: null

}

const loginReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case 'LOGIN':
      return { ...state, ...action.payload };
    case 'ADMIN_LOGIN_SUCCESS':

      break;
    default:
      return state;
      break;
  }
}

export default loginReducer;
