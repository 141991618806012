import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  Input,
  Button,
  ButtonGroup,
  ListGroup, ListGroupItem
} from "reactstrap";

const Error = (props) => {

  return (
    <div className="mt--5" fluid>
      <Card className="card-profile shadow" style={{marginBottom: 20, width: 400}}>
        <CardHeader className=" bg-transparent">
          <h3 className=" mb-0">Not Found</h3>
        </CardHeader>
        <CardBody>
          <h2>Requested URL not found</h2>
        </CardBody>
      </Card>
    </div>
  )
}

export default Error;