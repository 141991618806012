import React, { useEffect, useState } from "react";
import VendorHeader from "components/Headers/VendorHeader.js";
import { getAllRestaurantsList } from "api";
import Add from '../Restaurants/Add';
import RestaurantsTable from '../../components/Vendor/RestaurantsTable';
import AddRestaurantModal from '../../components/Vendor/AddRestaurantModal';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Button,
} from "reactstrap";
import Pagination from "react-reactstrap-pagination";
import '../../components/Vendor/restaurants.scss'

class Restaurant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCreateModal: false, restaurants: [], page: 1, items: 1, pageNo: 1,toggleAdd:false,
      showEditModal:false
    }
    this.getDataFromApi()
  }

  getDataFromApi = (page = 1) => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      getAllRestaurantsList(token, page)
        .then(res => {
          if ('docs' in res) {
            if (res.docs.length != 0) {
              this.setState({
                restaurants: res.docs,
                page: res.pages,
                items: res.total,
                pageNo: page,
              })
            }
            else {
            }
          } else {

          }
        })
        .catch(() => {

        });
    } else {
      this.props.history.push('/');
    }
  }
  handleSelectedFunc = (selectedPage) => {
    this.getDataFromApi(selectedPage)
    // this.setState({ selectedPage: selectedPage });
  }

  render() {
    return (
      <>
        <VendorHeader />
        <Container className="mt--7" fluid>
          <Row>
            <div className=" col">
              <Card className=" shadow ">
                <CardHeader className="bg-transparent">
                  <h3 className=" mb-0">Restaurant</h3>
                  <Button onClick={() => this.setState({ showCreateModal: true })} color="primary">Add Restaurant</Button>
                </CardHeader>
                <CardBody>
                  {
                    this.state.restaurants.length == 0 ? "No Restaurants " : <><RestaurantsTable restaurants={this.state.restaurants} />
                      <Pagination totalItems={this.state.items} maxPaginationNumbers={this.state.page} pageSize={this.state.restaurants.length} onSelect={this.handleSelectedFunc} /></>
                  }
                </CardBody>
              </Card>
            </div>
          </Row>
          {/* <Add
            isOpen={addModalView}
            toggle={toggleAdd}
            refresh={(type) => _refresh(type)}
            vendor={vendor.data}
          /> */}
          {this.state.showCreateModal && <AddRestaurantModal onSave={()=>this.getDataFromApi()} onClose={()=>this.setState({showCreateModal: null})}/>}
        </Container>
      </>
    )
  }
}

export default Restaurant;