
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import {connect} from 'react-redux';
import { getAdminDashboardData,validateToken } from '../api';
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import {AdminDashboard} from '../redux/creators/index'
import routes from "routes.js";
import strings from 'lang/strings';

class Admin extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      language:"en"
    }
  }
  componentDidMount() {

    const loggedIn = localStorage.getItem('token');
    const language = localStorage.getItem('language');
    console.log("language",language)
    this.setState({
      language:language
    })
    if(loggedIn === null) {
      this.props.history.push('/admin-panel');
    }else{
      validateToken()
      .then((result) => {
        if(result === "Unauthorized"){
          this.props.history.push('/admin-panel');
        }
      })
      .catch((err) => {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          this.props.history.push('/admin-panel');
      })
    }
    getAdminDashboardData()
    .then((result) => {
      console.log("Anta",result)
      this.props.updateAdminDashbord(result.data)
    })
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
      {strings.setLanguage(this.state.language)}
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/dashboard",
            imgSrc: require("assets/img/logo.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStatetoProps = state =>{
  return state;
}

const mapDispatchtoProps = dispatch =>{
  return {
    updateAdminDashbord:  (data)=>dispatch(AdminDashboard(data))
  }
}


export default connect(mapStatetoProps,mapDispatchtoProps)(Admin);
