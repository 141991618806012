import React, { useState, useEffect } from "react";
import Header from "components/Headers/Header.js";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Input,
  Button, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup
} from "reactstrap";

import moment from 'moment';
import swal from 'sweetalert';
import { createFilter } from 'react-search-input';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurantsForAdmin } from '../../api';
import { getVendors, getQrCodeData } from '../../api';
import QrCode from 'react-qr-code';
import Add from './Add';
import Edit from './Edit';
const saveSvgAsPng = require('save-svg-as-png')
const imageOptions = {
  scale: 5,
  encoderOptions: 1,
  backgroundColor: 'white',
}

const columns = (_renderBtn) => [
  {
    name: 'Vendor',
    selector: 'vendor.name',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Latitude',
    selector: 'latitude',
    sortable: true,
  },
  {
    name: 'Longitude',
    selector: 'longitude',
    sortable: true,
  },
  {
    name: 'Discount',
    selector: 'discount',
    sortable: true,
  },
  {
    name: 'Date Added',
    cell: row => moment(row.createdAt).format('MMM Do YYYY h:mm a'),
    sortable: true,
  },
  {
    name: '',
    cell: row => _renderBtn(row),
    right: true,
  },
];

const Restaurant = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const vendor = useSelector(state => state.vendor);

  const [addModalView, setAddModal] = useState(false);
  const [editModalView, setEditModal] = useState(false);

  const [selected, setSelected] = useState({});
  const [showQrCode, setShowQrCode] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [restaurant, setRestaurant] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    getRestaurantsForAdmin()
      .then((result) => {
        console.log("Restaurant", isLoading)
        setData(result.data)
        setIsLoading(false)
      });
  }, [addModalView, editModalView]);

  const onChangeValue = (e) => {

  };
  const toggleAdd = () => setAddModal(!addModalView);
  const toggleEdit = () => setEditModal(!editModalView);
  const downloadQrCode = (params) => {
    let body = document.getElementById(`qr-${qrCodeValue}`).children;
    let svg = body[0];
    saveSvgAsPng.saveSvgAsPng(svg, `${restaurant.name}.png`, imageOptions);
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                <Button color="default"
                  onClick={() => setAddModal(true)}
                >
                  Add New Restaurant
                </Button>
              </CardHeader>
              <CardBody>
                <DataTable
                  title="List of Restaurants"
                  columns={columns(_renderBtn)}
                  data={data}
                  pagination
                  progressPending={isLoading}
                  subHeader
                  subHeaderComponent={
                    <Input onChange={onChangeValue} style={{ width: 250 }} placeholder="Search" />
                  }
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Add
        isOpen={addModalView}
        toggle={toggleAdd}
        refresh={(type) => _refresh(type)}
        vendor={vendor.data}
      />
      <Modal isOpen={showQrCode} toggle={() => setShowQrCode(false)}>
        <ModalHeader>
          {restaurant.name}
        </ModalHeader>
        <ModalBody id={`qr-${qrCodeValue}`}>
          <QrCode id="1" level="L" value={qrCodeValue} renderAs="png" />
          <div>
            <Button onClick={downloadQrCode}>Download</Button>
          </div>
        </ModalBody>
      </Modal>

      {(Object.keys(selected).length !== 0) ?
        <Edit
          isOpen={editModalView}
          toggle={toggleEdit}
          selected={selected}
          vendor={vendor.data}
          refresh={(type) => _refresh(type)}
        />
        : null}
    </>
  )

  function _renderBtn(data) {
    return <>
      <Button color="default" size="sm" onClick={() => _edit(data)}>Edit</Button>
      <Button color="default" size="sm" onClick={() => _qrCode(data)}>Qr Code</Button>
    </>
  }

  function _edit(data) {
    setEditModal(true);
    setSelected({});
    setTimeout(() => {
      setSelected(data);
    }, 500);
  }

  function _qrCode(data) {
    setRestaurant(data);
    getQrCodeData(data.id)
      .then((result) => {
        if (result.status == "success") {
          setShowQrCode(true)
          setQrCodeValue(result.data.value)
        }
        else
          swal('Cannot generate qrcode.')
      })
  }

  function _refresh(type) {
    // if(type === 'add') {
    //   setAddModal(false);
    // }else {
    //   setEditModal(false);
    // }

    // dispatch(getRestaurant());
  }
}

export default Restaurant;