import { getRestaurantOrders } from 'api'
import React, { useEffect, useState } from 'react'
import Table from 'reactstrap/lib/Table'
import Pagination from "react-reactstrap-pagination";
export default function ReportTable() {
    const [token, setToken] = useState(localStorage.getItem("token"))
    const [ordersList, setOrdersList] = useState([])
    const [items, setItems] = useState(5)
    const [page, setPage] = useState(1)
    const [selected, setSelected] = useState(0)

    useEffect((props) => {
        if (token) {
            getRestaurantOrders(token,page)
                .then((res) => {
                    setItems(res.total)
                    setPage(res.pages)
                    setOrdersList(res.docs)
                })
        }
    }, [selected]);

    const handleSelectPage = (selected) => {
        setSelected(selected)
        setPage(selected)
    }
    

    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ordersList.map((item, index) =>
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.user_name}</td>
                                <td>{item.amount}</td>
                                <td>{item.user_email}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <Pagination totalItems={items} maxPaginationNumbers={page} pageSize={ordersList.length} onSelect={handleSelectPage} />
        </div>
    )
}
