import React, { useState, useEffect } from "react";
import Header from "components/Headers/Header.js";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Input,
  Button,
  ButtonGroup
} from "reactstrap";

import moment from 'moment';
import { createFilter } from 'react-search-input';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersForAdmin } from '../../api';

import Add from './Add';
import Edit from './Edit';

const columns = () => [
  {
    name: 'Restaurant',
    selector: 'restaurant.name',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'user_name',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'user_email',
    sortable: true,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
  },
  {
    name: 'Date Added',
    cell: row => moment(row.createdAt).format('MMM Do YYYY h:mm a'),
    sortable: true,
  },
];

const Users = (props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([])
  const [search, setSearch] = useState('');
  const KEYS_TO_FILTERS = ['data.name'];

  useEffect(() => {
    getOrdersForAdmin()
      .then((result) => {
        console.log("Orders", result)
        setOrders(result.data)
      });
  }, []);

  const onChangeValue = (e) => {
    if (e.target.value) {
      let filteredData = orders.filter((item) => {
        return item.user_name && item.user_name.toLowerCase().includes(e.target.value.toLowerCase())
      })
      console.log("filtered", e.target.value)
      setOrders(filteredData);
    } else {
      getOrdersForAdmin()
        .then((result) => {
          console.log("Orders", result)
          setOrders(result.data)
        });
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
              </CardHeader>
              <CardBody>
                <DataTable
                  title="List of Purchased"
                  columns={columns()}
                  data={orders}
                  pagination
                  progressPending={isLoading}
                  subHeader
                  subHeaderComponent={
                    <Input onChange={onChangeValue} style={{ width: 250 }} placeholder="Search" />
                  }
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )




}

export default Users;