import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  Input,
  Button,
  ButtonGroup,
  ListGroup, ListGroupItem
} from "reactstrap";

const StoreProfle = (props) => {

  var offers = [];
  for(var i = 0; i<6; i++) {
    offers.push({
      title: 'Title '+i,
      description: 'Description '+i,
      price: '30 SAR',
      photo: 'https://media.apnarm.net.au/media/images/2019/01/22/imagev170129559c4fe6c35f9bbb5529d0076fa-mz4ujz2l1q6oakt7or2_t1880.jpg'
    })
  }

  return (
    <div className="mt--5" fluid>
      <Row>
        <Col xs="12" md="8">
          <Card className="card-profile shadow" style={{marginBottom: 20}}>
            <CardHeader className=" bg-transparent">
              <h3 className=" mb-0">Offer(s)</h3>
            </CardHeader>
            <CardBody>
              
              <Row>
                {offers.map((item, k) => {
                  return (
                    <Col key={k} xs="12" md="4">
                      <Card className="shadow" style={{marginBottom: 30}}>
                        <CardImg src={item.photo} style={{width: '100%', height: 200, objectFit: 'cover'}}/>
                        <CardBody>
                          <h3>{item.title}</h3>
                          <strong style={{color: '#2CD978'}}>{item.price}</strong>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>

            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4">
          <Card className="card-profile shadow" style={{marginBottom: 20}}>
            <CardImg 
              src="https://dynamic.brandcrowd.com/asset/logo/d5787595-2257-44db-a8b3-29880bf587f6/logo?v=4" 
              style={{width: '100%', height: 250, objectFit: 'cover'}}
              />
            <CardBody>
              <ListGroup flush>
                <ListGroupItem><h3>Store Name</h3></ListGroupItem>
                <ListGroupItem>Address</ListGroupItem>
                <ListGroupItem>Email</ListGroupItem>
                <ListGroupItem>Phone</ListGroupItem>
                <ListGroupItem>Social Media</ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StoreProfle;