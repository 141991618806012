import React, { useEffect, useState, Component } from 'react'
import { Table, Spinner } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { updateStatement } from 'typescript';
import { getAddressFromLatLng } from '../../api';
import { IMAGE_URL } from '../../helpers/api';
import swal from 'sweetalert';
import EditRestaurantModal from './EditRestaurantModal';
export default class RestaurantsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.restaurants.map((item, index) => "loading"),
            showEditModal: false
        }

    }
    componentDidMount() {
        this.props.restaurants.map((item, index) => {
            getAddressFromLatLng(item.latitude, item.longitude).then(res => {
                this.setState({ [index]: res })
            })
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props != nextProps || this.state != nextState;

    }

    componentWillReceiveProps = () => {
        this.setState({ ...this.props.restaurants.map((item, index) => "loading") }, () => {
            this.props.restaurants.map((item, index) => {
                if (item.latitude !== null && item.longitude !== null)
                    getAddressFromLatLng(item.latitude, item.longitude).then(res => {
                        this.setState({ [index]: res })
                    })
            })
        })

    }

    updateState = () => {
        this.setState({ ...this.props.restaurants.map((item, index) => "loading") }, () => {
            this.props.restaurants.map((item, index) => {
                if (item.latitude !== null && item.longitude !== null)
                    getAddressFromLatLng(item.latitude, item.longitude).then(res => {
                        this.setState({ [index]: res })
                    })
            })
        })
    }

    openEditModal = (restaurant) => {
        this.setState({
            showEditModal: true,
            restaurant: restaurant
        })
    }

    deleteRestaurant = (params) => {
        
    }

    toggle = () => {
        this.setState({
            showEditModal: false,
        })
    }

    render() {
        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            {/* <th>Image</th> */}
                            <th>Name</th>
                            <th>Location</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.restaurants.map((item, index) => {
                            // getAddressFromLatLng(item.latitude, item.longitude).then(res=>{
                            //     setAddress( addresses.map((item2, index2)=>index===index2?res: item2 ))
                            // })

                            return (
                                <tr key={index}>
                                    {/* <td>{item.image != null ? <img className="avatar" src={item.image.includes("logos")?IMAGE_URL+item.image:item.image} /> : "-"}</td> */}
                                    <td >{item.name}</td>
                                    <td ><div style={{ textOverflow: 'ellipsis', whiteSpace: 'pre-wrap' }}>{this.state[index] == "loading" ? <Spinner color="success" /> : this.state[index]}</div></td>
                                    <td>
                                        <Button onClick={() => this.openEditModal(item)}>
                                            Edit
                                    </Button>
                                        {/* <Button onClick={() => this.deleteRestaurant(item)}>
                                            Delete
                                    </Button> */}
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
                {this.state.showEditModal && <EditRestaurantModal toggle={this.toggle} restaurant={this.state.restaurant} />}
            </>
        )
    }
}
