import { GET_USERS, ADMIN_DASHBOARD_DATA } from '../creators';

const initialState = {
  loading: true,
  data: [],
  selected: {}
}

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_USERS:
      return {...state, data: action.payload, loading: action.loading}
      break;
    case ADMIN_DASHBOARD_DATA:
      return {...state, data: action.payload}
    default:
      return state;
  }
}

export default userReducer;
