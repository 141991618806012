import LocalizedStrings from 'react-localization';

import {ar} from './ar';
import {en} from './en';

let strings = new LocalizedStrings({
    ar:ar,
    en:en
   });


  export let updateLanguageCallback = null
  export const updateString= ( cb )=>{
    updateLanguageCallback = cb
  }

  export default strings