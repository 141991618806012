import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import loginReducer from './reducers/loginReducer';
import vendorReducer from './reducers/vendorReducer';
import restaurantReducer from './reducers/restaurantReducer';
import userReducer from './reducers/userReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  vendor: vendorReducer,
  restaurant: restaurantReducer,
  users: userReducer
});

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunkMiddleware),
  // other store enhancers if any
));
export { store };
