import React,{useEffect,useState} from "react";
import Header from "components/Headers/Header.js";

import {useDispatch} from 'react-redux'
import { AdminDashboard } from '../../redux/creators/index';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Input,
  Button,
  ButtonGroup
} from "reactstrap";

const Dashboard = (props) => {
  const [vendors, setVendors] = useState(0)
  const [restaurants, setRestaurants] = useState(0)
  const [orders, setOrders] = useState(0)
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      
    }
  }, [])

  return (
    <>
      <Header/>
      <Container className="mt--7" fluid>
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                <h3 className=" mb-0">Dashboard</h3>
              </CardHeader>
              <CardBody>

              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Dashboard;