import React, { useEffect, useState, withStyle } from "react";
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  Toast, ToastBody, ToastHeader
} from "reactstrap";
import { Formik } from 'formik';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { login, loginVendor } from '../../api';

import { updateLanguageCallback } from "../../lang/strings"
import strings from 'lang/strings';
import styles from './login.module.css';
const Login = (props) => {



  const dispatch = useDispatch();
  const history = useHistory();
  const [btnDisabled, setDisabled] = useState(false);
  let [isToast, showToast] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  // useEffect(() => {
  //   const loggedIn = window.localStorage.getItem('@thamin_vendor');
  //   if(loggedIn !== null) {
  //     props.history.push('/vendor/dashboard');
  //   }
  // }, []);
  const handleLanguageChange = (value) => {
    if (updateLanguageCallback)
      updateLanguageCallback(value)
  }
  return (
    <div className="main-content">
      <div className="header bg-gradient-primary py-7 py-lg-8">
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <img src={require('../../assets/img/logo.png')} width="80" />
                  <h1 className="">{strings.title_merchant}</h1>
                  <p className="text-lead">
                    {strings.description_merchant}
                  </p>
                </div>

                <Formik
                  initialValues={{
                    email: '',
                    password: ''
                  }}
                  onSubmit={(values) => {
                    if (values.email === '') {
                      setErrorEmail(strings.email_validation);
                      return false;
                    }

                    if (values.password === '') {
                      setErrorPassword(strings.password_validation);
                      return false;
                    }
                    setDisabled(true);
                    loginVendor(values).then(res => {
                      if (res.status === "success") {
                        dispatch({ type: 'LOGIN', payload: res.data })
                        window.localStorage.setItem("token", res.data.token)
                        window.localStorage.setItem("user", JSON.stringify(res.data.user))
                        history.push('vendor/dashboard')
                      } else {
                        swal({
                          title: strings.failure,
                          text: strings.invalid_credentials,
                          icon: 'error',
                          timer: 2500,
                          buttons: false,
                        })
                        setDisabled(false);
                      }
                    }).catch(err => err);
                  }}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit}>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={strings.email}
                            type="text"
                            name="email"
                            value={props.values.email}
                            onChange={props.handleChange}
                          />
                        </InputGroup>
                        <span style={{ color: 'red', fontSize: 12 }}>{errorEmail}</span>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={strings.password}
                            type="password"
                            name="password"
                            value={props.values.password}
                            onChange={props.handleChange} />
                        </InputGroup>
                        <span style={{ color: 'red', fontSize: 12 }}>{errorPassword}</span>
                      </FormGroup>
                      <div className="text-center">

                        <Button block type="submit" className="my-4" color="primary">
                          {(btnDisabled === true) ? <Spinner /> :strings.login}
                        </Button>

                      </div>
                    </Form>
                  )}
                </Formik>
                <div className={styles.languageSelect}>
                  <label onClick={() => handleLanguageChange("en")}>{strings.english}</label>
                  <label onClick={() => handleLanguageChange("ar")}>{strings.arabic}</label>
                </div>
              </CardBody>

            </Card>

          </Col>
        </Row>
      </Container>
    </div>
  )
}



export default Login;