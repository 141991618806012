import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, Input, FormGroup, Label, CustomFileInput,
    Row, Col
} from 'reactstrap';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import React, { Component } from 'react';
import { addRestaurant } from '../../api';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Spinner from 'reactstrap/lib/Spinner';

const google_key = "AIzaSyBabY1X-H8Sx0R3OTNZuqBvRqjMhvq-yFE";

const Map = withGoogleMap((props) => {
    return <GoogleMap
        defaultZoom={20}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        center={{ lat: props.lat, lng: props.lng }}>
        {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
})

export default class AddRestaurantModal extends Component {

    constructor(props) {
        super(props)
        const user = JSON.parse(localStorage.getItem("user"));
        
        this.state = {
            lat: -34.397,
            lng: 150.644,
            vendor_id: user.id,
            discount: 10,
            isLoading: false,
            name: "",
        }
    }

    componentDidMount() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                this.setState({ lat: position.coords.latitude, lng: position.coords.longitude })
            });
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state != nextState )
    //         return true;
    //     return false;
    // }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({
                    lat: latLng.lat,
                    lng: latLng.lng
                })
            })
            .catch(error => console.error('Error', error));
    };

    addRestaurant = () => {
        this.setState({ isLoading: true })
        addRestaurant({ ...this.state })
            .then((result) => {
                this.props.onClose()
                this.props.onSave()
            })
    }

    handleInput = (e) => {
        var target = e.target.name;
        this.setState({ [target]: e.target.value })
    }

    handleImageInput = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            this.setState({
                image: URL.createObjectURL(e.target.files[0]),
                file: e.target.files[0],
                fileName: e.target.files[0].name,
            });
        }
    }

    render() {
        let { onClose } = this.props
        return (
            <div>
                <Modal isOpen={true} toggle={onClose} >
                    <ModalHeader className="modal-header" toggle={onClose}>Add Restaurnat</ModalHeader>
                    <Form>
                        <ModalBody>
                            <Row>
                                {/* <Col xs="12" md="6">
                                    <FormGroup>
                                        <Label>Image</Label>
                                        <CustomFileInput type="file" onChange={this.handleImageInput} name="image" />
                                    </FormGroup>
                                </Col> */}
                                <Col xs="12" md="12">
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input type="text" value={this.state.name} onChange={this.handleInput} name="name" placeholder="Enter Restaurant Name" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Select Location</Label>
                                <PlacesAutocomplete
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    onSelect={this.handleSelect}
                                >
                                    {({ getInputProps, loading, suggestions, getSuggestionItemProps }) => {
                                        return <div>
                                            <Input
                                                {...getInputProps({
                                                    placeholder: 'Search Places ...',
                                                    className: 'location-search-input',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    }}

                                </PlacesAutocomplete>
                                <Map
                                    lat={this.state.lat}
                                    lng={this.state.lng}
                                    isMarkerShown
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.addRestaurant}>{this.state.isLoading ? <Spinner /> : "Add"}</Button>
                            <Button color="secondary" onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        )
    }
}

