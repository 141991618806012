/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState} from "react";
import { Link } from "react-router-dom";
import strings,{ updateLanguageCallback } from "../../lang/strings"
// reactstrap components
import {
  DropdownMenu,DropdownItem,UncontrolledDropdown,DropdownToggle,
  Form,FormGroup,InputGroupAddon,InputGroupText,Input,InputGroup,
  Navbar,Nav,Container,Media
} from "reactstrap";

const AdminNavbar = (props) => {
  const [language, setLanguage] = useState(localStorage.getItem("language"))
  function _logout() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    props.history.push('/admin-panel');
  }

  const handleLanguageChange = (value) => {
    setLanguage(value)
    if (updateLanguageCallback)
      updateLanguageCallback(value)
  }

  return (
    
    <>
    {strings.setLanguage(language)}
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/">
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {/* 
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                    />
                  </span>
                  */}
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {strings.Account}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => _logout()}>
                  <i className="ni ni-button-power" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>

            </UncontrolledDropdown>
            <UncontrolledDropdown>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {strings.Language}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => handleLanguageChange("en")}>
                  <span>{strings.english}</span>
                </DropdownItem>
                <DropdownItem onClick={() => handleLanguageChange("ar")}>
                  <span>{strings.arabic}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
export default AdminNavbar;
