export const ar = {
    title_merchant:"لوحة البائع",
    description_merchant:"تسجيل الدخول إلى لوحة البائعين",
    title_admin:"لوحة الادارة",
    email:"عنوان البريد الالكترونى",
    email_validation:"رجاءا أدخل بريدك الإلكتروني",
    password:"كلمه السر",
    password_validation:"من فضلك أدخل رقمك السري",
    failure:"بالفشل",
    invalid_credentials:"اسم المستخدم وكلمة المرور غير صالحة",
    login:"تسجيل الدخول",
    english:"الإنجليزية",
    arabic:"عربى",
    Dashboard:"لوحة القيادة",
    Restaurant:"مطعم",
    Report:"نقل",
    Settings:"إعدادات",
    account:"الحساب",
    logout:"تسجيل خروج",
    Vendor:"بائع",
    Restaurants:"مطاعم",
    Users:"لمستخدمون",
    admin_panel:"لوحة تحكم المشرف",
    success:"نجاح",
    Redirecting:"إعادة توجيه",
    cpanel:"لوحة التحكم",
    Purchase:"عملية الشراء",
    Account:"الحساب",
    Language:"لغة",
    old_password:"كلمة المرور القديمة",
    new_password:"كلمة السر الجديدة",
    submit:"يُقدِّم"
};