import React, { useEffect } from "react";
import VendorHeader from "components/Headers/VendorHeader.js";
import { getVendorDashboardData } from '../../api';
import ReportTable from '../../components/Vendor/ReportTable';

import {
  CardBody, Row, Card, CardHeader, Button
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Container from "reactstrap/lib/Container";



const Reports = (props) => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      getVendorDashboardData(token)
        .then(res => {
        })
        .catch(() => {

        });
    } else {
      history.push('/');
    }

  });

  return (
    <>
      <VendorHeader />
      <Row>
        <div className=" col">
          <Container>
            <Card className=" shadow ">
              <CardHeader className="bg-transparent">
                <h3 className=" mb-0">Orders</h3>
              </CardHeader>
              <CardBody>
                <ReportTable />
              </CardBody>
            </Card>
          </Container>
        </div>
      </Row>
    </>
  )
}



export default Reports;