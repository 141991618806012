import React, { useState } from "react";
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Form,
  Input, 
  FormGroup, 
  Label,
  Spinner
} from 'reactstrap';
import { Formik } from 'formik';
import swal from 'sweetalert';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addVendor } from '../../api';

const Add = (props) => {

  const dispatch = useDispatch();
  const [btnDisabled, setDisabled] = useState(false);
  
  var initialValues = {
    name: '',
    email: '',
    password: ''
  }

  return (
    <Formik
      initialValues={initialValues}

      onSubmit={(values, { resetForm }) => {
        setDisabled(true);

        addVendor(values).then(res => {
          if(res.data.status === 'failure') {
            setDisabled(false);
            
            if(res.data.errors.name !== undefined) {
              swal('Error',res.data.errors.name[0], 'error');
            }

            if(res.data.errors.email !== undefined) {
              swal('Error',res.data.errors.email[0], 'error');
            }

            if(res.data.errors.password !== undefined) {
              swal('Error',res.data.errors.password[0], 'error');
            }

            if(res.data.message !== undefined) {
              swal('Error',res.data.message, 'error');
            }
          }else {
            props.isClose();
            setDisabled(false);
            resetForm(initialValues);
          }
        });
      }}
      >
      {(form) => (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle}>Add Vendor</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Vendor Name</Label>
                <Input type="text" name="name" value={form.values.name} onChange={form.handleChange}/>
              </FormGroup>

              <FormGroup>
                <Label>Email</Label>
                <Input type="email" name="email" value={form.values.email} onChange={form.handleChange}/>
              </FormGroup>

              <FormGroup>
                <Label>Password</Label>
                <Input type="password" name="password" value={form.values.password} onChange={form.handleChange}/>
              </FormGroup>

            </Form>
            
          </ModalBody>
          <ModalFooter>
            {(btnDisabled === true) ? <Spinner /> : 
              <>
                <Button color="primary" onClick={form.handleSubmit}>Submit</Button>{' '}
                <Button color="secondary" onClick={() => _cancel(form)}>Cancel</Button>
              </>
            }
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  )

  function _cancel(form) {
    props.isClose();
    form.resetForm(initialValues);
  }
}

export default Add;