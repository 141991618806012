import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AdminLogin from "views/Login";

import VendorLayout from "layouts/Vendor.js";
import VendorLogin from "views/Login/VendorLogin";

import PublicLayout from "layouts/Public.js";

import { Provider } from "react-redux";
import { store } from "./redux/appRedux";
import { useDispatch } from "react-redux";
import { validateToken, validateMerchantToken } from "./api";

import strings, {
  updateLanguageCallback,
  updateString,
  changeLanguage,
} from "lang/strings";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "it",
    };

    let token = localStorage.getItem("token");
    let url = this.props.location.pathname;
    let url_paths = url.split("/");
    if (url == "/" || url_paths[1] == "vendor") {
      if (token !== null) {
        validateMerchantToken(token).then((res) => {
          if (res !== "Authorized") {
            this.props.history.push("/");
          }
        });
      }
    } else if (
      url == "admin" ||
      url == "admin-panel" ||
      url_paths[1] == "admin"
    ) {
      if (token !== null) {
        validateToken(token).then((res) => {
          if (res !== "Authorized") {
            this.props.history.push("/admin-panel");
          }
        });
      } else {
        this.props.history.push("/admin-panel");
      }
    }
  }

  componentDidMount = () => {
    // changeLanguage('it')
    let lang = localStorage.getItem("language");
    updateString(this.handleLanguageChange);
    updateLanguageCallback(lang ? lang : "en");
  };
  handleLanguageChange = async (value) => {
    localStorage.setItem("language", value);
    this.setState({ language: value });
    let body = document.querySelector("body");
    let html = document.querySelector("html");
    if (body && value === "en") {
      html.setAttribute("lang", "en");
      body.style.direction = "ltr";
    }

    if (body && value === "ar") {
      html.setAttribute("lang", "ar");
      body.style.direction = "rtl";
    }
  };

  render() {
    // strings.setLanguage(this.state.language);
    strings.setLanguage(this.state.language);

    return (
      <Switch>
        {/* For Publis */}
        <Route path="/public">
          <PublicLayout {...this.props} />
        </Route>

        {/* For Admin */}
        <Route path="/admin">
          <AdminLayout {...this.props} />
        </Route>
        <Route path="/admin-panel">
          <AdminLogin {...this.props} />
        </Route>
        {/* For Stores */}
        <Route path="/vendor">
          <VendorLayout {...this.props} />
        </Route>
        <Route path="/">
          <VendorLogin {...this.props} />
        </Route>
        {/* <Redirect from="/" to="/vendor/dashboard" /> */}
      </Switch>
    );
  }
}

const AppHOC = withRouter(App);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AppHOC />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
