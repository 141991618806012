import Dashboard from "views/VendorDashboard";
import Restaurant from "views/VendorDashboard/Restaurant";
import Reports from "views/VendorDashboard/Reports";
import Settings from "views/VendorDashboard/Settings";
import Icons from "views/examples/Icons";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-chart-bar-32 text-default",
    component: Dashboard,
    layout: "/vendor"
  },
  {
    path: "/restaurant",
    name: "Restaurant",
    icon: "ni ni-building text-default",
    component: Restaurant,
    layout: "/vendor"
  },
  {
    path: "/reports",
    name: "Report",
    icon: "ni ni-bullet-list-67 text-default",
    component: Reports,
    layout: "/vendor"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-default",
    component: Settings,
    layout: "/vendor"
  },
  /*
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-settings-gear-65 text-default",
    component: Icons,
    layout: "/vendor"
  }
  */
];
//var routes = [];
export default routes;
