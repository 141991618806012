import React, { useState, useEffect } from "react";
import {
  Button, Row, Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  FormGroup,
  Label,
  Spinner
} from 'reactstrap';
import { Formik } from 'formik';
import swal from 'sweetalert';
import moment from 'moment';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { useDispatch } from 'react-redux';
import { addRestaurantForAdmin, getVendors } from '../../api';

const Add = (props) => {

  const dispatch = useDispatch();
  const [btnDisabled, setDisabled] = useState(false);
  const [vendors, setVendors] = useState([])

  var initialValues = {
    vendor_id: '',
    name: '',
    image: [],
    location: '',
    latitude: '',
    longitude: '',
    discount: '0'
  }

  useEffect((props) => {
    getVendors()
      .then((result) => {
        setVendors(result.data)
      })
  }, [])


  return (
    <Formik
      initialValues={initialValues}

      onSubmit={(values, { resetForm }) => {
        setDisabled(true);

        // if (values.image.length === 0) {
        //   setDisabled(false);
        //   swal('Error', 'Please add the image', 'error');
        //   return false;
        // }

        addRestaurantForAdmin(values).then(res => {
          if (res.data.status === 'failure') {
            setDisabled(false);

            if (res.data.errors.vendor_id !== undefined) {
              swal('Error', res.data.errors.vendor_id[0], 'error');
            }

            if (res.data.errors.latitude !== undefined) {
              swal('Error', res.data.errors.latitude[0], 'error');
            }

            if (res.data.errors.longitude !== undefined) {
              swal('Error', res.data.errors.longitude[0], 'error');
            }

            if (res.data.errors.discount !== undefined) {
              swal('Error', res.data.errors.discount[0], 'error');
            }

            if (res.data.message !== undefined) {
              swal('Error', res.data.message, 'error');
            }
          } else {
            props.refresh('add');
            props.toggle()
            setDisabled(false);
            resetForm(initialValues);
          }
        })
        .catch((err) => {
          setDisabled(false);
        });
      }}
    >
      {(form) => (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle}>Add Restaurant</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                {/* <Col xs="12" md="6">
                <FormGroup>
                  <Label>Image</Label>
                  <Input type="file" onChange={(e) => _selectImage(e, form)} />
                </FormGroup>
                </Col> */}
                <Col xs="12" md="12">
                <FormGroup>
                  <Label>Restaurant Name</Label>
                  <Input type="text" name="name" value={form.values.name} onChange={form.handleChange} />
                </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>Vendor</Label>
                <Input type="select" name="vendor_id" value={form.values.vendor_id} onChange={form.handleChange}>
                  {vendors.map((item, k) => {
                    return (
                      <option
                        key={k}
                        value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>Location</Label>
                <PlacesAutocomplete
                  value={form.values.location}
                  onChange={(address) => _handleChangeLocation(address, form)}
                  onSelect={(address) => _handleSelectLocation(address, form)}>
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <Input
                        {...getInputProps({
                          placeholder: 'Search Location...',
                          className: 'location-search-input',
                        })} />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={suggestion.description}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </FormGroup>

              <FormGroup>
                <Label>Discount</Label>
                <Input type="number" name="discount" value={form.values.discount} onChange={form.handleChange} />
              </FormGroup>

            </Form>

          </ModalBody>
          <ModalFooter>
            {(btnDisabled === true) ? <Spinner /> :
              <>
                <Button color="primary" onClick={form.handleSubmit}>Submit</Button>{' '}
                <Button color="secondary" onClick={() => _cancel(form)}>Cancel</Button>
              </>
            }
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  )

  function _selectImage(e, form) {
    console.log(e.target.files[0])
    form.setFieldValue('image', e.target.files[0]);
  }

  function _handleChangeLocation(address, form) {
    form.setFieldValue('location', address);
  }

  function _handleSelectLocation(address, form) {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        form.setFieldValue('location', address);
        form.setFieldValue('latitude', latLng.lat.toString());
        form.setFieldValue('longitude', latLng.lng.toString());
      })
      .catch(error => console.error('Error', error));
  }

  function _cancel(form) {
    props.toggle();
    form.resetForm(initialValues);
  }
}

export default Add;