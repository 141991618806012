export const GET_VENDORS = 'GET_VENDORS';
export const GET_VENDOR_BY_ID = 'GET_VENDOR_BY_ID';
export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const GET_RESTAURANTS_BY_ID = 'GET_RESTAURANTS_BY_ID';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_BY_ID = 'GET_USERS_BY_ID';
export const LOGIN = 'LOGIN';
export const ADMIN_DASHBOARD_DATA = 'ADMIN_DASHBOARD_DATA';

export const LoginAction = (data) => {
    return {type:LOGIN, payload: data}
}

export const AdminDashboard = (data) =>{
    return {type:ADMIN_DASHBOARD_DATA,payload:data}
}