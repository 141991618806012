import Dashboard from "views/Dashboard";
import Vendor from "views/Vendor";
import Restaurants from "views/Restaurants";
import Users from "views/Users";
import Icons from "views/examples/Icons";

import React from 'react'

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-chart-bar-32 text-default",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/vendor",
    name: "Vendor",
    icon: "ni ni-building text-default",
    component: Vendor,
    layout: "/admin"
  },
  {
    path: "/restaurant",
    name: "Restaurants",
    icon: "ni ni-cart text-default",
    component: Restaurants,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Purchase",
    icon: "ni ni-single-02 text-default",
    component: Users,
    layout: "/admin"
  },
  /*
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-settings-gear-65 text-default",
    component: Icons,
    layout: "/admin"
  },
  */
];
//var routes = [];
export default routes;
