import React, { useState } from "react";
import VendorHeader from "components/Headers/VendorHeader.js";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Button,
  Spinner,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormFeedback,
} from "reactstrap";

import strings from "lang/strings";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "api";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required(strings.password_validation),
  newPassword: Yup.string().required(strings.password_validation),
});

const Settings = (props) => {
  const [btnDisabled, setDisabled] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [errors, setErrors] = useState({
    oldPassword: false,
    newPassword: false,
  });

  const handleSubmit = () => {
    console.log('values',newPassword,oldPassword)
    let error = {...errors};
    let errorsExists = false;
    if (!newPassword) {
      errorsExists = true;
      error.newPassword = strings.password_validation;
      setErrors(error);
    }else{
      error.newPassword = "";
      setErrors(error);
    }
    if (!oldPassword) {
      errorsExists = true;
      error.oldPassword = strings.password_validation;
      setErrors(error);
    }else{
      error.oldPassword = "";
      setErrors(error);
    }
    if(!errorsExists){
      resetPassword({old_password:oldPassword,new_password:newPassword})
      .then(res => {
        console.log('hello')
      })
    }
  };
  return (
    <>
      <VendorHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                <h3 className="mb-0">Settings</h3>
              </CardHeader>
              <CardBody>
                <h3>Reset Password</h3>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={strings.old_password}
                      type="password"
                      onChange={(e) => {
                        e.persist();
                        setOldPassword(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <span style={{ color: 'red', fontSize: 12 }}>{errors?.oldPassword && errors.oldPassword}</span>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={strings.new_password}
                      type="password"
                      onChange={(e) => {
                        e.persist();
                        setNewPassword(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <span style={{ color: 'red', fontSize: 12 }}>{errors?.newPassword && errors.newPassword}</span>
                </FormGroup>
                <div className="text-center">
                  <Button
                    block
                    className="my-4"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {strings.submit}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
