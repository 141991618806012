import { GET_VENDORS, GET_VENDOR_BY_ID } from '../creators';

const initialState = {
  loading: true,
  data: [],
  selected: {}
}

const vendorReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_VENDORS:
      return {...state, data: action.payload, loading: action.loading}
      break;
    case GET_VENDOR_BY_ID:
      return {...state, selected: action.payload}
      break;
    default:
      return state;
      break;
  }
}

export default vendorReducer;
